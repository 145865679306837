/* Chetan CSS starts */
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}
html {
  background-color: #fff;
}
#chat-popup-overlay {
  padding: 0px;
  /* background-color: rgba(77, 92, 116, 0.6); */
  background-color: #fff;
  top: 54px;
  overflow-y: hidden;
}
#chat-popup-overlay .styles_modal__gNwvD {
  /* max-width: 100%; */
  max-width: 100%;
  width: 100%;
  padding: 0;
  box-shadow: none;
  height: calc(100vh - 0px);
  /* overflow: hidden; */
  /* height: 100%; */
}
.store-chat-header {
  background-color: #2561a8;
  padding: 9px 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.store-chat-header h3 {
  color: #fff;
  font-size: 14px;
  font-weight: normal;
}
.rounded-cross {
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  border: 2px solid #fff;
  border-radius: 50%;
  line-height: 1;
  height: 30px;
  width: 30px;
  text-align: center;
  cursor: pointer;
}
.chats-heading {
  /* margin: 0 18px 7px; */
  margin-bottom: 7px;
  font-size: 14px;
  padding: 0 15px;
}
.name-num p,
.mess-time p {
  line-height: 1.5;
}
.name-num {
  overflow: hidden;
}
.mess-time p {
  font-weight: 400;
}
.mess-time {
  text-align: right;
  white-space: nowrap;
}
.initial {
  margin-top: 0;
  flex: 0 0 28px;
  color: #000;
}
.chat-name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.chat-info {
  transition: all 0.3s ease-in;
}
.chat-info:hover {
  /* background-color: #ecf2f4; */
  background-color: #cde4ff;
}
.mobile-chat-tabs {
  display: none;
  width: 100%;
  text-align: center;
}
/* .chatbot-left {
  height: calc(100vh - 94px);
} */

/* message suggestions starts */
.suggestions-cntr {
  margin: 8px -7px;
}
.suggestions-cntr span {
  background: #fff;
  opacity: 0.7;
  padding: 5px 15px;
  display: inline-block;
  margin: 4px 7px;
  max-width: 250px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  border-radius: 1px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
}
.suggestions-cntr div {
  position: relative;
  display: inline-block;
}
.suggestions-tick span {
  opacity: 1;
}
.suggestions-tick span:after {
  content: "";
  width: 5px;
  height: 10px;
  border: solid #47b04b;
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
  position: absolute;
  top: 0.5px;
  right: 6.5px;
}
.suggestions-tick span:before {
  content: "";
  width: 18px;
  height: 18px;
  position: absolute;
  border-radius: 50%;
  background: #c7e8c8;
  top: -3px;
  right: 0px;
}
/* message suggestions ends */

/* mobile chats tab starts */
.chat-tabs-mobile {
  display: none;
}
.mobile-arrow {
  display: none;
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
}
.chats-count {
  position: relative;
  display: inline-block;
  margin-right: 5px;
}
.chats-count span {
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 7px;
  font-weight: 700;
  transform: translate(-50%, -50%);
  margin-left: -0.5px;
  margin-top: -0.5px;
  color: #fff;
}
.mobile-chat-tabs .nav-tabs .nav-link {
  font-weight: 400;
  font-size: 13px;
  color: rgba(37, 97, 168, 0.4);
  padding: 5px 10px;
  border-radius: 20px;
  line-height: 1;
  min-height: 25px;
  vertical-align: middle;
  display: flex;
  align-items: center;
}
.mobile-chat-tabs .nav-tabs {
  justify-content: center;
  padding: 10px 0;
  margin: 0 15px;
  border-bottom: 1px solid rgb(37, 97, 168, 0.2);
}
.mobile-chat-tabs .nav-tabs .nav-link.active {
  background: #2561a8;
  border: 0;
  color: #fff;
  padding-right: 20px;
  padding-left: 20px;
}
.mobile-chat-tabs .nav-tabs .nav-link.active .chats-count span {
  color: #2561a8;
}
.chat-bubble-white {
  display: none;
}
.mobile-chat-tabs .nav-tabs .nav-link.active .chats-count .chat-bubble-white {
  display: block;
}
.chat-bubble-blue {
  display: block;
}
.mobile-chat-tabs .nav-tabs .nav-link.active .chats-count .chat-bubble-blue {
  display: none;
}
.mobile-chat-tabs .tab-content {
  padding: 7px 0px 7px 15px;
}
.mobile-chat-header {
  font-weight: 700;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.6);
  text-align: left;
}
.face-name {
  font-weight: 700;
  font-size: 11px;
  color: rgba(0, 0, 0, 0.6);
  display: block;
}
.chat-face-cntr {
  width: 50px;
  margin: auto;
  position: relative;
}
.chat-face-inner-cntr {
  border-radius: 6px;
  overflow: hidden;
}
.chat-face-cntr img {
  width: 100%;
}
.chat-detail-outer-cntr {
  display: flex;
  padding-top: 10px;
  overflow: auto;
  min-height: 81px;
  margin-left: -4px;
}
.chat-detail-outer-cntr .chat-detail-middle-cntr:last-child .chat-detail-cntr {
  margin-right: 15px;
}
.chat-detail-outer-cntr .chat-detail-middle-cntr:first-child {
  padding-left: 4px;
}
.chat-initial {
  background: #ddf6fc;
  display: block;
  height: 50px;
  line-height: 50px;
  color: #11b4db;
  text-transform: uppercase;
}
.chat-notification-count {
  position: absolute;
  top: 50%;
  left: 50%;
  color: #fff;
  font-size: 9px;
  transform: translate(-50%, -50%);
  font-weight: 700;
  margin-top: 1px;
  margin-left: -0.5px;
}
.chat-detail-cntr {
  margin-right: 25px;
  position: relative;
}
.chat-detail-cntr.active .face-name {
  color: #2561a8;
}
.chat-notification-cntr {
  width: 17px;
  position: absolute;
  top: -12px;
  left: -4px;
}
/* .chat-detail-cntr.active::after, */
.online {
  /* content: ""; */
  position: absolute;
  width: 9px;
  height: 9px;
  top: 6px;
  right: -4px;
  border-radius: 50%;
  background: #45ee8e;
  border: 1px solid #fff;
}
.chatcontentdivtab .cke_inner {
  display: flex;
  flex-direction: column-reverse;
}
.mobile-ck-send {
  position: absolute;
  width: 30px;
  height: 30px;
  line-height: 25px;
  right: 15px;
  top: 15px;
  /* bottom: 55px; */
  cursor: pointer;
  border: 0.5px solid #215faa;
  border-radius: 50%;
  text-align: center;
}

.mobile-ck-send-btn {
  position: absolute;
  width: 30px;
  height: 30px;
  line-height: 25px;
  right: 15px;
  top: 56px;
  /* bottom: 55px; */
  cursor: pointer;
  border: 0.5px solid #215faa;
  border-radius: 50%;
  text-align: center;
}

.mobile-ck-send img {
  width: 13px;
}
#mobile-tabs-overlay {
  background: rgba(0, 0, 0, 0.65);
  padding: 20px;
}
#mobile-tabs-popup {
  background: #ecf2f4;
  padding: 15px;
  padding-right: 5px;
  border-radius: 8px;
  box-shadow: none;
  width: 100%;
}
.mobile-chat-popup .search-customerAddSrch {
  font-size: 13px;
  /* padding-left: 15px !important; */
  padding-right: 2px !important;
  margin: 0;
  width: calc(100% - 30px) !important;
  border: 1px solid #d8dfe2 !important;
  border-right: 0 !important;
}
/* .mobile-chat-popup */
.search-customerAddSrch::placeholder {
  color: #2561a8;
  padding: 0;
  font-weight: 400;
}
.search-customerAddSrch-plcholder::placeholder {
  font-size: 16px;
  color: #4a4a4a;
}
.search-customerAddSrch-plcholder {
  font-size: 16px;
  padding-left: 22px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.search-customerAddSrch-plcholder + .seacrh-img-addsearch {
  width: 7%;
}
.search-customerAddSrch-plcholder + .seacrh-img-addsearch .srch-imge {
  filter: brightness(0);
}
.mobile-chat-popup .input-group-addon {
  width: 30px;
  border: 1px solid #d8dfe2 !important;
  border-left: 0 !important;
}
.mobile-card-cntr {
  display: flex;
  align-items: flex-end;
}
.searchtxt-new {
  /* margin-top: 15px; */
  margin-bottom: 10px;
}
.no-record {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin: 6px 0;
  font-size: 12px;
}
/* mobile chats tab ends */

/* chat trail starts */
.chat-trail-img {
  flex: 0 0 50px;
  border-radius: 5px;
  overflow: hidden;
}
.chat-trail-img img {
  width: 100%;
}
.chat-trail-chat {
  padding: 10px 20px;
  border-radius: 5px;
  border-top-left-radius: 0;
  background: #58a4ff;
  color: #fff;
  word-break: break-all;
}
.chat-trail-chat .card-body {
  margin: 0 -10px;
}
.chatcontentDiv::-webkit-scrollbar {
  width: 6px;
}
.chatcontentDiv::-webkit-scrollbar-track {
  background: #ddd;
  border-radius: 10px;
}
.chatcontentDiv::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}
.chatcontentDiv::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.chat-trail-cntr {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
  margin-right: 10px;
  max-width: 80%;
  margin-left: 10px;
  margin-top: 10px;
}
.chatcontentDiv .chat-trail-cntr:last-child {
  margin-bottom: 0px;
}
.chat-trail-cntr-right {
  flex-direction: row-reverse;
  margin-left: auto;
}
.bot-mark {
  background-color: #f7b500;
  font-weight: 700;
  font-size: 10px;
  position: absolute;
  top: 9px;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  left: -20px;
}
.chat-trail-chat-cntr {
  margin-top: 15px;
  margin-left: 10px;
  white-space: pre-line;
  position: relative;
}
.chat-trail-cntr-right .chat-trail-chat-cntr {
  margin-right: 10px;
  margin-left: 0;
}
.chat-trail-cntr-right .chat-trail-chat {
  border-top-right-radius: 0;
  border-top-left-radius: 5px;
  background: #fff;
  color: #4a4a4a;
}
.chat-trail-time {
  font-size: 12px;
  display: block;
  margin-top: 8px;
}
.chat-trail-cntr-right .chat-trail-time {
  text-align: right;
}
#chat-popup .butn,
#chat-popup .butn-inv {
  font-weight: 700;
  border-radius: 8px;
  font-size: 14px;
  height: 40px;
  padding: 6px 10px;
  padding-left: 15px;
  min-width: 95px;
}
#chat-popup .butn {
  color: rgba(255, 255, 255, 0.85);
}
#chat-popup .butn-inv {
  background: transparent;
}
.send-up {
  width: 24px;
  filter: invert(1);
  /* position: relative;
  top: -1px; */
  margin-left: 4px;
  float: right;
}
.chat-btn-cntr {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  padding-right: 10px;
}
/* chat trail ends */

/* ck editor starts */
/* .cke_toolbox {
  display: flex;
  overflow: auto;
}
.cke_toolgroup {
  display: flex;
}
.cke_top {
  background: #fff !important;
  border-top: 1px solid #f0f0f0 !important;
}
.cke_bottom {
  display: none !important;
} */
/* ck editor ends */

/* mobile search starts */
.mobile-search-img {
  width: 13px;
}
.mobile-search-img img {
  width: 100%;
}
.mobile-search-cntr {
  position: absolute;
  top: 10px;
  padding: 0 15px;
  display: flex;
  width: 100%;
}
.mobile-search-input input {
  width: 100%;
  border: 0;
  padding: 3px 10px;
  font-size: 13px;
  color: #3c3c3c;
}
.mobile-search-input input::placeholder {
  color: rgba(60, 60, 60, 0.4);
  padding: 0;
  font-weight: 400;
}
/* mobile search ends */

/* recommended list starts */
.recommended-cntr {
  /* margin-left: 20px; */
  /* margin-top: 15px; */
  margin-top: 6px;
}
.recommended-cntr .send-up {
  margin-left: 25px;
}
/* recommended list ends */

/* recommended list mobile starts */
.recommended-list-popup > div {
  height: 100%;
}
.recommended-list-popup {
  padding: 15px !important;
  height: 100%;
}
.recommended-cntr .chat-btn-cntr {
  margin: 0;
  padding: 0;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.recommended-cntr .chat-btn-cntr button {
  margin: 10px 0;
}
/* recommended list mobile ends */

/* payment link starts */
.payment-link-butn {
  float: right;
  /* margin-right: 18px; */
  margin-top: 30px;
}
.payment-details {
  /* margin-left: 17px; */
  /* padding-top: 5px; */
}
.payment-details span {
  font-size: 18px;
  font-weight: 700;
}
.payment-details label {
  font-size: 14px;
  font-weight: 700;
  color: #000;
  display: block;
  margin-bottom: -2px;
}
/* payment link ends */

/* Chetan CSS ends */

/* Deepak CSS starts */
.chattitlelbl {
  padding: 15px;
  font-size: 15px;
  /* font-size: 20px; */
  font-weight: bold;
  color: #2561a8;
}
.chatdivtitle {
  height: 80px;
  background-color: #ecf2f4;
  width: 100%;
  height: 20%;
}
.chatdivtitle .nav-tabs .nav-link.active {
  border: 1px solid #2561a8;
  background: #2561a8;
  color: #fff;
}
.chatdivtitle .nav-tabs .nav-link {
  color: #2561a8;
  border: 1px solid #2561a8;
  padding: 7px 18px;
  border-radius: 10px;
}
.chatactionbtn {
  background: #fff;
}
.chatactionbtn label {
  color: #2561a8;
}
.chatcontentDiv {
  height: 310px;
  /* background-color: #F5F5F5 !important; */
  overflow: auto;
  /* margin: 20px; */
  margin: 0px;

  /* margin-right: 10px; */
}
.chatcontentRow {
  margin: 0px;
  background-color: #f5f5f5;
  height: 40%;
  width: 100%;
  /* overflow: auto; */
}

.chatcontentdivtab {
  margin: 0;
  background-color: #ecf2f4;
  padding: 20px;
  height: 100vh;
  width: 100%;
  max-height: 220px;
  overflow: auto;
}
.chatcontentdivtab::-webkit-scrollbar {
  width: 6px;
}
.chatcontentdivtab::-webkit-scrollbar-track {
  background: #ddd;
  border-radius: 10px;
}
.chatcontentdivtab::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}
.chatcontentdivtab::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.chatcontentdivtab .tab-content {
  padding-top: 18px;
  padding-bottom: 0;
  cursor: pointer;
}
.chatcontentdivtab .nav-tabs .nav-link.active {
  color: #fff;
  background-color: #2561a8;
}
.chatcontentdivtab .nav-tabs .nav-link {
  color: #000000;
  border: 1px solid #2561a8;
  box-shadow: none;
  border-radius: 8px;
  background-color: #fff;
  font-weight: 500;
  font-size: 10px;
  padding: 8px 12px;
}
.chatcontentdivtab .nav-tabs {
  border: none;
}
.chatcontentdivtab .nav-item {
  margin-right: 10px;
}
.chatcontentdivtab ul .nav-item:last-child {
  margin-right: 0px;
}
.message-div {
  /* margin: 20px; */
  position: relative;
}
.chatcontentdivtab .cke_contents {
  height: 100px !important;
}
.message-div textarea {
  height: 100px;
  resize: none;
  width: 100%;
  border: 1px solid #d8dfe2;
  border-radius: 5px;
  padding: 16px 55px 16px 50px;
  color: #4a4a4a;
}
.message-div textarea::placeholder {
  padding: 0;
  font-weight: 400;
}
.message-initial {
  position: absolute;
  top: 13px;
  left: 13px;
  background-color: #ddf6fc;
  border-radius: 5px;
  width: 28px;
  height: 28px;
  color: #11b4db;
  font-size: 12px;
  font-weight: 700;
  line-height: 28px;
  text-align: center;
}

.searchtxt {
  background-color: #fff !important;
  border-radius: 5px 0px 0px 5px;
  margin-left: 15px;
}
.searchtxt-span {
  background-color: #fff !important;
  border-radius: 0px 5px 5px 0px !important;
}
*:focus {
  outline: none;
}
.product-card .card {
  padding: 10px;
  margin: 10px 0;
  border-radius: 8px;
  box-shadow: 0px 2px 4px 0 rgb(219, 219, 219, 0.5);
}
.mobile-chat-popup .product-card .card:last-child {
  margin-bottom: 0px;
}
.searchtxt-new .search-customerAddSrch {
  background-color: #ecf2f4;
  width: 96%;
  height: 43px;
  padding: 10px;
  border: none;
  padding-left: 15px;
  margin-left: 0;
}
.bkcprdt {
  background-color: #f3f3f3;
  padding: 10px;
  width: 100%;
}
.chat-product-name {
  font-size: 14px;
  font-weight: bold;
  color: #000000;
}
.chat-product-code {
  font-size: 12px;
  font-weight: 700;
  color: #686767;
}
.chat-product-prize {
  font-size: 12px;
  font-weight: bold;
  color: #000000;
}
.chat-product-url {
  font-size: 12px;
  font-weight: 700;
  color: #2aa2fd;
  width: 100%;
  cursor: pointer;
  word-break: break-all;
}
.chat-product-img {
  width: 100%;
}

.selectdot {
  width: 25px;
  position: absolute;
  top: 0px;
  z-index: 1;
  right: 6px;
}
.storeUpbtn {
  background-color: #fff;
  margin: auto;
  margin-top: 10px;
  height: 30px;
  padding: 0;
  transform: translateX(50%);
}
.product-card {
  height: 138px;
  overflow: auto;
}
.product-card::-webkit-scrollbar,
.schedule-left-cntr::-webkit-scrollbar {
  width: 5px;
}
.product-card::-webkit-scrollbar-track,
.schedule-left-cntr::-webkit-scrollbar-track {
  background: #e9e9e9;
  border-radius: 10px;
}
.product-card::-webkit-scrollbar-thumb,
.schedule-left-cntr::-webkit-scrollbar-thumb {
  background: #2561a8;
  border-radius: 10px;
}
.product-card::-webkit-scrollbar-thumb:hover,
.schedule-left-cntr::-webkit-scrollbar-thumb:hover {
  background: #2561a8;
}
.product-card .col-md-4 {
  align-items: flex-end;
}
.selectdot img {
  width: 100%;
}
.storeUpbtn img {
  width: 20px;
}
.schedule-btn-outer-cntr {
  display: flex;
}
.message-icon-cnt {
  position: absolute;
  background-color: #eb4d4b;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  border-radius: 10%;
  font-size: 11px;
  color: #fff;
  font-weight: 700;
  top: -13px;
  right: -10px;
}
.schedule-btn-cntr {
  display: flex;
  flex-wrap: wrap;
  /* margin-left: -5px; */
  flex-wrap: nowrap;
  overflow: auto;
}
.schedule-btn-cntr::-webkit-scrollbar {
  display: none;
}
.schedule-btn-cntr button {
  cursor: pointer;
}
.schedule-btn-cntr button,
.select-slot-cntr,
.schedule-right-cntr input {
  font-size: 10px;
  position: relative;
  padding: 6px 24px 6px 6px;
  margin: 0 5px;
  margin-top: 14px;
  /* flex: 0 1 22%; */
  /* flex: 0 1 29%; */
  border-radius: 3px;
  min-width: auto;
  white-space: nowrap;
}
.s-yellow-btn {
  color: #000;
  border-color: #f7b500;
  background-color: #fff;
  cursor: pointer;
}
.s-green-btn {
  color: #000;
  border-color: #30ba93;
  background-color: #fff;
  cursor: pointer;
}
.s-red-btn {
  color: #000;
  border-color: #bd3939;
  background-color: #fff;
  cursor: initial !important;
}
.s-red-active {
  background-color: #bd3939;
  color: #fff;
  border-color: #bd3939;
}
.s-yellow-active {
  background-color: #f7b500;
  color: #fff;
  border-color: #f7b500;
}
.s-green-active {
  background-color: #30ba93;
  color: #fff;
  border-color: #30ba93;
}
.s-lable {
  font-weight: 700;
  margin-bottom: -8px;
  color: #000;
  display: block;
  font-size: 12px;
}
.s-img-select {
  width: 18px;
  right: 3px;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  filter: invert(1);
}
.selectdot-blue {
  height: 20px;
  line-height: 17px;
  flex: 0 0 20px;
  background-color: #2561a8;
  border-radius: 50%;
  margin-top: 19px;
  text-align: center;
  margin-left: 10px;
  cursor: pointer;
}
.selectdot-blue-left {
  margin-left: 0;
  margin-right: 10px;
}
.selectdot-blue img {
  width: 14px;
  filter: invert(1);
}
.selectdot-blue-left img {
  transform: rotate(180deg);
}
.schedule-left-cntr {
  max-height: 200px;
  overflow: auto;
}
.schedule-left-cntr > div {
  margin-bottom: 15px;
}
.schedule-left-cntr > div:last-child {
  margin-bottom: 0px;
}
.schedule-right-outer-cntr {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}
.schedule-right-cntr {
  display: flex;
  justify-content: space-evenly;
}
.schedule-right-cntr input {
  border: 1px solid #b6b6b6;
  margin-left: 0;
  margin-right: 0;
  width: 75px;
  padding: 5px 8px;
  font-size: 14px;
}
.schedule-right-cntr .s-lable {
  color: #4a4a4a;
  font-size: 13px;
}
.ant-tooltip-inner {
  font-size: 10px;
  max-width: 100px;
  min-height: auto;
  text-align: center;
  letter-spacing: 0.5px;
  line-height: 1.4;
  padding-right: 13px;
  padding-left: 13px;
}
.ant-tooltip-arrow::before {
  width: 8px;
  height: 8px;
}
.schedule-btn-cntr *:focus {
  outline: none;
}
.pd-0 .card-body {
  padding: 0;
}
.isSendClick-dsle {
  pointer-events: none;
  cursor: no-drop;
}
.messagecount {
  background-color: darkseagreen;
  color: #fff;
  border: 2px solid #f1f1f1;
  font-size: 11px;
  border-radius: 50%;
  padding: 1px 6px 2px 5px;
  text-align: center;
}
.firstbox {
  width: 20%;
}
.secondbox {
  width: 53%;
}
.thirdbox {
  width: 27%;
  position: relative;
}
.firstbox-full {
  width: 24%;
}
.secondbox-open {
  width: 69% !important;
}
.thirdbox-close {
  width: 7% !important;
}
.thirdbox-close .shoppingbag{
  display: none;
}
.thirdbox-close .uptabs .nav-tabs {
  display: none;
}
.thirdbox-close .nameplate span {
  display: none;
}
.thirdbox-close .contactbox {
  display: none;
}
.thirdbox-close .pointstable {
  display: none;
}
.thirdbox-close .prodtabl1 {
  display: none;
}
.thirdbox-close .updateprofilelinkbtn {
  display: none;
}
.thirdbox-close .pin {
  display: none;
}
.thirdbox-close .nameplate li:first-child {
  margin-right: 0;
}
.thirdbox-close .nameplate {
  margin: 0;
}
.thirdbox-close .tab-content {
  padding: 20px 15px 15px !important;
  align-items: center;
  display: block;
  margin: auto;
  text-align: center;
}
/* .uptabs {
  position: relative;
} */
.uptabs .nav-tabs {
  border-bottom: none;
  display: inline-block;
  background: #2561a8;
  color: #fff;
  border-radius: 10px;
  margin: 15px 30px;
  padding: 0 2px;
}
.uptabs .nav-tabs .nav-link.active {
  color: #2561a8;
  border-bottom: none;
  box-shadow: none;
  font-weight: 600;
  background: #fff;
  padding: 6px 12px;
  border-radius: 8px;
}
.uptabs .nav-tabs .nav-link {
  padding: 8px 12px;
  border: 0;
  font-weight: normal;
  text-transform: uppercase;
  font-size: 12px;
  color: #fff;
}
.uptabs .nav-tabs .nav-item {
  margin-bottom: 0;
}
.uptabs .tab-content {
  padding: 0 15px 15px;
}
.nameplate li:first-child {
  border: 1px solid #64c5b4;
  padding: 5px;
  border-radius: 50%;
  margin-right: 10px;
}
.nameplate li {
  vertical-align: middle;
}
.nameplate .namelabel {
  background: #ddf6fc;
  padding: 10px 17px;
  border-radius: 50%;
  font-weight: bold;
}
.nameplate h3 {
  font-size: 14px;
  font-weight: 600;
  display: inline-block;
  white-space: normal;
  word-break: break-word;
}
.nameplate span {
  display: block;
  background: #f5c645;
  padding: 1px 10px;
  font-size: 11px;
  border-radius: 50px;
  text-align: center;
  width: 70px;
  margin-top: 4px;
}
.nameplate img {
  height: 13px;
  margin: 0 4px;
}
.contactbox {
  float: right;
}
.chatdivtitle li {
  margin-right: 10px;
}
.contactbox ul {
  text-align: right;
}
.contactbox label {
  font-size: 12px;
  color: #666;
  display: block;
}
/* .contactbox li:first-child {
  margin-right: 50px;
} */
.contactbox li:first-child {
  border: none;
  padding: 0;
  border-radius: 0;
  margin: 0;
  display: block;
}
.contactbox p {
  font-size: 12px;
  color: #333;
  font-weight: 600;
}
/* .pointstable table tbody tr:first-child {
  border-top: 1px solid #eeeeee;
} */
.nameplate {
  /* padding: 0 15px; */
  padding: 0 0px;
}
.pointstable table td {
  padding: 10px 0;
  font-size: 12px;
}
.pointstable table label {
  color: #333;
}
.pointstable table span {
  text-align: right;
  font-size: 16px;
  color: #2561a8;
}
.pointstable {
  margin-bottom: 10px;
  padding: 0 15px;
}
.pointstable td:nth-child(1) {
  float: left;
}
.pointstable td:nth-child(2) {
  float: none;
  text-align: center;
}
.pointstable td:nth-child(3) {
  float: right;
}
.pointstable td:nth-child(1) span {
  text-align: left;
}
.pointstable td:nth-child(2) span {
  text-align: center;
}
.pointstable td:nth-child(3) span {
  text-align: right;
}

.itemtable table thead tr {
  background-color: transparent !important;
  border-bottom: none;
}
.itemtable table th {
  padding: 0;
  color: #999;
  font-weight: normal;
}
.itemtable table td {
  padding: 10px 0;
  font-size: 12px;
}
.itemtable table label {
  color: #333;
}
.itemtable {
  margin-bottom: 10px;
}
.itemtable td:nth-child(1) {
  float: left;
}
.itemtable td:nth-child(2) {
  float: none;
  text-align: center;
}
.itemtable td:nth-child(3) {
  float: right;
}
.itemtable td:nth-child(1) label {
  text-align: left;
}
.itemtable td:nth-child(2) label {
  text-align: center;
}
.itemtable td:nth-child(3) label {
  text-align: right;
}
.itemtable th:nth-child(1) {
  float: left;
}
.itemtable th:nth-child(2) {
  float: none;
  text-align: center;
}
.itemtable th:nth-child(3) {
  float: right;
}
.itemtable table tbody tr {
  border-bottom: none;
}
.lasttransaction h3 {
  font-size: 14px;
  display: inline-block;
  font-weight: 600;
  margin-bottom: 10px;
}
.lasttransaction label {
  font-size: 12px;
  color: #999;
  display: block;
}
.lasttransaction li:last-child {
  float: right;
}
.lasttransaction li {
  width: 50%;
}
.lasttransaction {
  padding-bottom: 4px;
}
.lasttransaction span {
  font-size: 11px;
  color: #333;
  font-weight: 600;
}
.lasttransaction {
  margin-bottom: 12px;
}
.lasttransaction ul {
  margin-bottom: 10px;
  display: flex;
}
.ordersbox ul {
  margin-bottom: 7px;
}
.ordersbox h3 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
  display: inline-block;
}
.ordersbox label {
  font-size: 12px;
  color: #999;
  display: block;
}
.ordersbox li {
  width: 100px;
}
.ordersbox li:last-child {
  float: right;
}
.ordersbox li:last-child {
  float: right;
}
.ordersbox span {
  font-size: 12px;
  color: #333;
  font-weight: 600;
}
.ordersbox {
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 12px;
  padding-bottom: 5px;
}
.DownArw {
  margin-left: 6px;
  height: 7px;
}
.insightsbox {
  margin-bottom: 12px;
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 10px;
}
.insightsbox h3 {
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
}
.insightsbox p {
  font-size: 12px;
  color: #666;
  margin-bottom: 3px;
  font-weight: normal;
}
.updateprofilelinkbtn {
  background: none;
  border-radius: 50px;
  text-transform: uppercase;
  font-size: 12px;
  color: #2561a8;
  font-weight: 600;
  margin: auto;
  display: block;
}

.tabsbotbtn {
  outline: none !important;
  cursor: pointer;
  background: none;
  border-radius: 10px;
  text-transform: uppercase;
  font-size: 11px;
  color: #2561a8;
  font-weight: 600;
  margin: auto;
  margin-right: 6px;
  padding: 8px 6px;
}
.tabsbotbtn:last-child {
  margin-right: 0px;
}
.tabsbotbtn-box {
  text-align: center;
  margin-top: 15px;
}
.pastchatmobbtn {
  display: none;
}
.pin {
  position: absolute;
  height: 20px;
  right: 20px;
  top: 20px;
}
.productsbox .nav-tabs {
  margin: 0;
  background: none;
  border-radius: 0;
  padding: 0;
}
.productsbox .nav-tabs .nav-link.active {
  background: none;
  border-radius: 0;
  padding: 8px 12px;
}
.productsbox .nav-tabs .nav-link {
  color: #333;
  text-transform: capitalize;
  padding: 8px 12px;
  font-size: 16px;
}
.productsbox .nav-tabs .nav-link:hover {
  color: #333;
}
.productsbox .nav-tabs .nav-link:first-child {
  padding-left: 0;
}
.productsbox .nav-tabs .nav-item:last-child .nav-link {
  padding-right: 0;
}
.productsbox .selectalllabel {
  color: #2561a8;
  font-size: 13px;
  margin-bottom: 12px;
}
.productsbox .tab-content {
  padding: 0 0 15px;
}
.productsbox .prodboxx {
  position: relative;
  margin-right: 8px;
  margin-bottom: 12px;
  display: inline-table;
}
.ladyimg {
  width: 100px;
  height: 150px;
  border: 1px solid #cac4c4;
}
.productsbox tr {
  border-bottom: none;
}
.productsbox td {
  padding: 0;
}
.prodboxx h3 {
  font-size: 12px;
  color: #666;
  margin-top: 5px;
}
.prodboxx h4 {
  font-size: 12px;
  color: #333;
  margin-top: 3px;
}
.prodboxx span {
  font-size: 10px;
  color: #666;
  margin-top: 3px;
  display: block;
  padding: 0 !important;
}
.prodboxx .ant-checkbox {
  position: absolute;
  top: 3px;
  left: 6px;
  outline: none;
}
.prodboxx .ant-checkbox-inner {
  border-radius: 50%;
}
.cancelico {
  height: 21px;
  border: 2px solid;
  border-radius: 50%;
  padding: 3px;
  position: absolute;
  right: 2px;
  bottom: -7px;
  cursor: pointer;
}
.chatupperbtn {
  background: #fff;
  color: #2561a8;
  padding: 4px 0;
  font-size: 12px;
  font-weight: bold;
}
.prodtabl1 {
  max-height: 295px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 5px;
}
.prodtabl1::-webkit-scrollbar {
  width: 5px;
}
.prodtabl1::-webkit-scrollbar-track {
  background: #cac8c8;
  border-radius: 10px;
}
.prodtabl1::-webkit-scrollbar-thumb {
  background: #2561a8;
  border-radius: 10px;
}
.prodtabl1::-webkit-scrollbar-thumb:hover {
  background: #a5a3a3;
}
.prodtabl {
  width: 100%;
  max-height: 380px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 5px;
}
.prodtabl::-webkit-scrollbar {
  width: 5px;
}
.prodtabl::-webkit-scrollbar-track {
  background: #cac8c8;
  border-radius: 10px;
}
.prodtabl::-webkit-scrollbar-thumb {
  background: #2561a8;
  border-radius: 10px;
}
.prodtabl::-webkit-scrollbar-thumb:hover {
  background: #a5a3a3;
}
.search-customerChatSrch {
  width: 88%;
  height: 43px;
  padding: 10px;
  border: none;
  padding-left: 15px;
}
.seacrh-img-chatsearch {
  width: 12%;
  display: inline-block;
  height: 43px;
  padding: 13px 0 0 0 !important;
  border: none !important;
  border-radius: 0 !important;
  background: #ecf2f4 !important;
}
.Chatw {
  height: 16px;
  margin-right: 6px;
}
.chatsearchtxt-span {
  background-color: #fff !important;
  border-radius: 0px 5px 5px 0px !important;
}
.chat-trail-img .chat-initial {
  background: #ddf6fc;
  display: block;
  height: 45px;
  line-height: 45px;
  color: #11b4db;
  text-transform: uppercase;
  text-align: center;
  font-size: 16px;
}

.cls-charcount {
  float: right;
  font-weight: bold;
  color: #757575;
}

.cls-p-conf {
  margin: 7px;
  margin-top: 14px;
  position: relative;
  left: 32%;
}

.cls-p-sugg {
  padding: 15px;
}

#desktop-conf-popup {
  background: #ecf2f4;
  padding: 15px;
  padding-right: 5px;
  border-radius: 8px;
  box-shadow: none;
  width: 42%;
}

.chat-btn-conf {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  padding-right: 120px;
  padding-left: 116px;
}

#desktop-conf-mob-popup {
  background: #ecf2f4;
  padding: 8px;
  padding-right: 5px;
  border-radius: 8px;
  box-shadow: none;
  width: 100%;
}

.cls-p-conf-mob {
  margin: 7px;
  margin-top: 14px;
  position: relative;
  left: 24%;
}

.chat-btn-conf-mob {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  padding-right: 64px;
  padding-left: 65px;
}

.slot-span {
  display: block;
  margin: 10px 10px 10px 10px;
  padding: 5px 10px;
  text-align: center;
  color: #757575;
}

.chat-hist {
  padding: 90px 0px 30px 0px;
  position: relative;
  margin: auto;
  display: block;
}
.chat-hist .nav-item {
  margin: auto;
  display: block;
  width: 150px;
  text-align: center;
}

.chat-hist .nav-item .nav-link {
  color: #000000;
  border: 1px solid #2561a8;
  box-shadow: none;
  border-radius: 21px;
  background-color: #fff;
  font-weight: 500;
  font-size: 10px;
  padding: 7px 20px;
}

.chat-history .ant-table-body {
  padding: 8px 8px 8px 8px;
}

.chat-history .ant-table-pagination {
  padding: 8px 8px 8px 8px;
}

.chattabtitle {
  padding: 0 !important;
  width: 100% !important;
}

.chattitletab {
  background-color: #ecf2f4 !important;
  border: none !important;
  outline: 0 !important;
}

.storeaget-chat-ctn {
  background-color: #ebebeb;
  border-radius: 4px;
  width: 35px;
  height: 22px;
  color: #989898;
  font-family: Lato;
  font-weight: 700;
  display: inline-block;
  margin-right: 10px;
  padding: 1px;
  flex: 0 0 35px;
  text-align: center;
}
.agentchatdrop-down {
  margin-left: 0;
  width: 43%;
}
.histochat {
  max-height: 200px;
  overflow: auto;
}
.histochat::-webkit-scrollbar {
  width: 6px;
}
.histochat::-webkit-scrollbar-track {
  background: #ddd;
  border-radius: 10px;
}
.histochat::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}
.histochat::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.chatactionbtn {
  float: right;
  display: inline-block;
  margin-right: 10px;
  margin-left: 0px;
  outline: none !important;
  padding: 5px 10px;
  border-radius: 10px;
}
#actionmodalId {
  border-radius: 8px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
  padding: 10;
  /* margin-right: 105px; */
  margin-top: 83px;
  display: inline-block;
}
.actionmodalId-mr-add {
  margin-right: 360px;
}
.actionmodalId-mr-remove {
  margin-right: 105px;
}

.actionmodallbl {
  color: #000;
  font-size: 15px;
  font-weight: 400;
  cursor: pointer;
  padding: 7px;
}

.endchatlbl {
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
  color: #fff;
  font-weight: bold;
  background-color: #eb7372;
}
.chat-cntr .ant-select-arrow {
  display: block !important;
}
.showArrow .ant-select-arrow {
  display: block !important;
}

.add-cursor .ant-table-row {
  cursor: pointer;
}

.chathistory-div {
  padding: 10px;
}
.mg-rm.ant-pagination .ant-table-pagination {
  /* margin-bottom: 10px !important;
  padding-right: 10px !important; */
  margin: 0px !important;
}

.chathistory-tbl {
  background-color: #f5f5f5 !important;
  width: 100%;
}

.historychatcontnet {
  margin: 10px;
  background-color: #fcfcfc;
  overflow: auto;
  margin-top: 0px;
  margin-right: 17px;
  border-radius: 5px;
}
.historychatcontnet .add-bord {
  border-bottom: 1px solid #f3f3f3;
}

.chathistory-tbl .clickRowStyl {
  background-color: #f6f6f6;
}

.chathistory-tbl.ant-table-tbody > .clickRowStyl:hover > td {
  background-color: #f6f6f6;
}
.add-bord .chat-on-tuesday-jul {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
}
.msg-text-overlap {
  display: inline-block;
  white-space: nowrap;
  width: 140px;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}
.now-rap-tbl-txt.chatabcus
  .custom-antd-table.antd-table-campaign
  .ant-table-tbody
  > tr
  > td {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ant-pagination-item-ellipsis {
  background: none !important;
  box-shadow: none !important;
  padding: 0 !important;
  width: 22px !important;
  overflow: visible !important;
}
.rm-header tbody {
  display: flex;
  flex-wrap: wrap;
}
.rm-header table tbody tr {
  border-bottom: 0;
}
.rm-header .ant-table-thead {
  display: none;
}

.rm-header.custom-antd-table.antd-table-campaign .ant-table-tbody > tr > td {
  padding: 0 !important;
  margin: 0 !important;
}

.rm-header .ant-table {
  width: 100% !important;
}

.rm-header.ant-table-pagination.ant-pagination {
  margin: 0 !important;
  padding: 0 !important;
  width: 100%;
}

.rm-header.ant-pagination-item-active {
  border-color: #2561a8 !important;
}
.Cancelicow {
  display: none;
}
.BackArw {
  display: none;
}
.loader {
  margin-right: 88%;
  margin-top: 5px;
  font-size: 4px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load5 1.1s infinite ease;
  animation: load5 1.1s infinite ease;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
@-webkit-keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff,
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7),
      1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff,
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff,
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.5),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff,
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff,
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff,
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff;
  }
}
@keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff,
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7),
      1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff,
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff,
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.5),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff,
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff,
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff,
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff;
  }
}

.tagsbtn {
  background: #fff;
  color: #000000;
  font-weight: 400;
  outline: none !important;
  margin-right: 10px;
  margin-top: 15px;
  min-width: auto;
  cursor: pointer;
  padding: 8px 12px;
  font-size: 10px;
  text-transform: uppercase;
  border-radius: 8px;
}
.tagsbtn-active {
  text-transform: uppercase;
  font-size: 10px;
  padding: 8px 12px;
  background: #2561a8;
  color: #fff;
  font-weight: 400;
  outline: none !important;
  margin-right: 10px;
  margin-top: 15px;
  min-width: auto;
  cursor: pointer;
  border-radius: 8px;
}
.secondbox-open-new {
  width: 76% !important;
}
.Arwico {
  display: none;
}
.prodtabl1 .ant-collapse.ant-collapse-icon-position-left {
  border: none;
  background-color: #fff;
  border-radius: 0;
}
.prodtabl1 .ant-collapse-header {
  color: #333 !important;
  font-weight: 600;
}
.prodtabl1 .ant-collapse-content {
  border: none;
  color: #333;
}
.prodtabl1 .ant-collapse > .ant-collapse-item {
  border: none;
}
.prodtabl1 .ant-collapse-content > .ant-collapse-content-box {
  padding: 0 15px;
}
.prodtabl1 .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 8px 16px;
  padding-left: 36px;
}
.show-mobile {
  display: none;
}
.hide-message-title .ant-notification-notice-message {
  display: none;
}

/* Dropdown Button */
.chatdivtitle.dropbtn {
  background-color: #3498db;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer !important;
}

/* Dropdown button on hover & focus */
.chatdivtitle .dropbtn:hover,
.dropbtn:focus {
  background-color: #2980b9;
}

/* The container <div> - needed to position the dropdown content */
.chatdivtitle .dropdown {
  position: relative;
  display: inline-block;
  background-image: none !important;
  float: right;
  cursor: pointer !important;
  width: 200px;
}

/* Dropdown Content (Hidden by Default) */
.chatdivtitle .dropdown-content {
  width: 100%;
  display: none;
  position: absolute;
  background-color: #fff;
  margin-top: 35px;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.1);
  z-index: 1;
  border-radius: 6px;
}

/* Links inside the dropdown */
.chatdivtitle .dropdown-content label {
  color: black;
  padding: 25px;
  text-decoration: none;
  display: block;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  margin-left: 0;
  cursor: pointer !important;
}

/* Change color of dropdown links on hover */
.chatdivtitle .dropdown-content label:hover {
  background-color: #fff;
  border-radius: 6px;
  cursor: pointer !important;
}

/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.chatdivtitle .show {
  display: block;
}

.chat-initial-brd {
  border: 1px solid #9a9a9a;
  border-radius: 100%;
  width: 30px;
  height: 30px;
}
.insightsbox .ant-empty .ant-empty-normal {
  margin: 16px 0;
}
.agentchatdrop-down .ant-select-selection--single {
  background: none;
  border: none;
  font-size: 14px;
  color: #4a4a4a !important;
}
.agentchatdrop-down i {
  color: blue;
  line-height: 0;
}
/* Responsive starts */
@media all and (max-width: 768px) {
  .firstbox {
    width: 40%;
  }
  .secondbox {
    width: 60%;
  }
  .thirdbox {
    display: none;
  }
}

@media all and (max-width: 576px) {
  .Cancelicow {
    display: block;
  }
  .BackArw {
    display: block;
  }
  .show-desktop {
    display: none;
  }
  .show-mobile {
    display: block;
  }
  .firstbox-hide {
    display: none;
  }
  .firstbox-show {
    display: block;
  }
  .secondbox-open-new-hide {
    display: none;
  }
  .secondbox-open-new-show {
    display: block;
  }
  .firstbox {
    width: 100%;
    height: 94vh;
  }
  .secondbox {
    width: 100%;
  }
  .secondbox-open {
    width: 100% !important;
  }
  .thirdbox-shutter {
    height: 560px !important;
    max-height: 560px !important;
  }
  #chat-popup-overlay {
    top: 0;
  }
  .oc-bg {
    background-color: #ecf2f4;
    height: 73vh;
  }
  .chat-hist {
    padding: 0;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 15px;
  }
  .agentchatdrop-down {
    margin-left: 0;
    width: 43%;
  }
  .agentchatdrop-down:focus {
    outline: none;
    box-shadow: none;
    border: none;
  }
  .chat-trail-chat {
    background: #fff;
    color: #333;
  }
  .chat-trail-cntr {
    margin-left: 0px;
    margin-right: 0;
    max-width: 100%;
  }
  .chat-trail-cntr-right .chat-trail-chat {
    background: #cee0e6;
    color: #333;
  }
  .thirdbox {
    display: block;
    width: 100% !important;
    position: absolute;
    background: #fff;
    max-height: 90px;
    overflow: inherit;
    border-radius: 0 0 10px 10px;
  }
  .Arwico {
    height: 18px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 999999999999;
    bottom: -10px;
    display: block;
  }
  .chattabtitle {
    padding: 0 !important;
    width: 100% !important;
    margin-top: 28px;
  }
  .pin {
    display: none;
  }
  .loader {
    top: 13px;
    margin-left: 100px;
    margin-right: 0% !important;
    margin-top: 0px !important;
    font-size: 4px;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    position: relative;
    text-indent: -9999em;
    animation: load5 1.1s infinite ease;
    transform: translateZ(0);
  }
  .ant-pagination-item a {
    color: #4a4a4a !important;
  }

  .ant-pagination-item-active a {
    color: #4a4a4a !important;
  }
  .anticon svg {
    color: #4a4a4a;
  }
  #chat-popup-overlay {
    padding: 0px;
  }
  #chat-popup-overlay .styles_modal__gNwvD {
    max-width: 100%;
    background: whitesmoke;
  }
  .rounded-cross,
  /* .chatbot-left, */
  .chatdivtitle,
  .chat-tabs-desktop {
    display: none;
  }
  .chatdivtitle {
    display: inline-block;
  }
  .chatdivtitle ul {
    width: 100% !important;
  }
  .chatactionbtn {
    display: none !important;
  }
  a.nav-link.active.chattitletab {
    color: #2561a8;
    font-weight: bold;
  }
  .store-chat-header h3 {
    font-size: 13px;
    display: inline;
    margin-right: 0 !important;
  }
  .search-customerChatSrch::placeholder {
    font-size: 12px;
  }
  .chats-heading {
    font-size: 12px;
  }
  .store-chat-header {
    justify-content: inherit;
    padding-left: 15px;
    display: block;
    padding-right: 15px;
  }
  .BackArw {
    height: 17px;
    width: 17px;
    border-radius: 50%;
    border: 1px solid #fff;
    padding: 3px;
    float: right;
    margin-top: 3px;
    margin-right: 10px;
  }
  .Cancelicow {
    height: 17px;
    border-radius: 50%;
    border: 1px solid #fff;
    padding: 3px;
    float: right;
    margin-top: 3px;
  }
  .mobile-arrow,
  .mobile-chat-tabs,
  .chat-tabs-mobile {
    display: block;
    height: 12px;
  }
  .mobile-ck-send {
    /* top: 25px; */
    top: 7px;
  }
  .mobile-ck-send-btn {
    top: 43px;
  }
  #chat-popup .butn,
  #chat-popup .butn-inv {
    min-width: 85px;
    height: 36px;
    font-size: 14px;
  }

  /* right side part starts */
  .chatcontentdivtab {
    padding: 0;
  }
  .chatcontentdivtab .nav-tabs .nav-link {
    font-size: 13px;
    border: 0;
    padding: 5px 20px;
    text-transform: lowercase;
    color: #404d52;
    background: #fff;
    border-radius: 5px;
    border: 1px solid #2561a8;
  }
  .chatcontentdivtab .nav-tabs .nav-link::first-letter {
    text-transform: uppercase;
  }
  .chatcontentdivtab .nav-tabs {
    margin: 10px 0px 10px 15px;
  }
  /* .chatcontentdivtab .nav-item {
    margin-left: 0;
    padding-right: 10px;
  } */
  .chatcontentdivtab .nav-tabs .nav-item:last-child {
    padding-right: 15px;
  }
  .chatcontentdivtab .tab-content {
    padding: 0;
  }
  .message-div {
    /* margin: 0; */
    margin: 14px;
    margin-bottom: 10px;
  }
  .message-initial {
    width: 23px;
    height: 23px;
    line-height: 23px;
    font-size: 11px;
    border-radius: 3px;
    top: 9px;
    left: 9px;
  }
  .message-div textarea {
    border-color: transparent;
    border-radius: 4px;
    font-size: 12px;
    height: 80px;
    padding: 11px 55px 11px 40px;
  }
  .message-div textarea::placeholder {
    font-size: 12px;
  }
  .chatcontentdivtab .cke_contents {
    height: 80px !important;
  }
  .chat-trail-chat {
    font-size: 12px;
    padding: 8px 15px;
  }
  .bot-mark {
    font-size: 8px;
    padding: 4px;
    top: 7px;
    left: -15px;
  }
  .chat-trail-chat .card-body {
    margin: 0 -7px;
  }
  .chat-trail-time {
    font-size: 10px;
  }
  .chat-trail-img {
    flex: 0 0 35px;
    display: none;
  }
  .chat-trail-chat-cntr {
    margin-top: 10px;
  }
  .chat-trail-time {
    margin-top: 4px;
  }
  .chat-trail-cntr {
    margin-bottom: 10px;
  }
  .chatcontentDiv {
    margin-right: 5px;
    margin-left: 5px;
    /* height: calc(100vh - 314px); */
    /* height: calc(100vh - 404px); */
    height: calc(100vh - 395px);
  }
  /* right side part ends */

  /* ck editor starts */
  /* .cke_top {
    padding: 0 !important;
    padding-right: 8px !important;
  }
  .cke_toolgroup {
    margin-bottom: 1px !important;
  } */
  /* ck editor ends */

  /* card modal starts */
  .send-up {
    width: 22px;
  }
  .bkcprdt label {
    display: block;
  }
  .storeUpbtn {
    display: none;
  }
  .product-card {
    max-height: calc(100vh - 171px);
    height: 100%;
    padding-right: 10px;
  }
  .searchtxt-new {
    margin-top: 0;
    padding-right: 10px;
  }
  .mobile-card-img {
    flex: 0 0 100px;
    margin-right: 15px;
  }
  .chat-product-name {
    font-size: 10px;
  }
  .chat-product-code,
  .chat-product-prize,
  .chat-product-url {
    font-size: 9px;
  }
  .chat-product-prize {
    margin-top: 6px;
  }
  .selectdot {
    width: 22px;
    top: -13px;
    right: -13px;
  }
  .product-card .card {
    margin: 0;
    margin-bottom: 10px;
  }
  /* card modal ends */

  /* schedule modal starts */
  .schedule-visit-popup > div {
    height: 100%;
  }
  .schedule-mobile-cntr {
    padding: 0 5px 5px 5px;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  }
  .selectdot-blue {
    display: none;
  }
  .s-lable {
    font-size: 12px;
    margin-bottom: -4px;
  }
  .schedule-btn-cntr button,
  .select-slot-cntr,
  .schedule-right-cntr input {
    margin-top: 11px;
    font-weight: 700;
    font-size: 10px;
    padding: 4px 24px 4px 8px;
    flex: 0 1 29%;
  }
  .schedule-left-cntr > div {
    margin-bottom: 20px;
  }
  .schedule-left-cntr::after {
    content: "";
    border-bottom: 1px solid #d7d7d7;
  }
  .schedule-visit-popup {
    padding: 0 !important;
    /* height: 100%; */
  }
  .schedule-left-outer-cntr {
    padding: 20px 0 20px 15px;
    border-bottom: 1px solid #d7d7d7;
  }
  .schedule-right-cntr {
    justify-content: initial;
    padding: 15px;
  }
  .schedule-right-cntr .s-lable {
    font-size: 12px;
    color: #000;
  }
  .schedule-right-cntr > div {
    margin-right: 20px;
  }
  .schedule-right-cntr > div:last-child {
    margin-right: 0px;
  }
  .schedule-right-cntr input {
    width: 80px;
    color: #000;
    padding: 4px 8px;
  }
  .schedule-visit-popup .chat-btn-cntr {
    padding: 0 15px 15px;
  }
  .schedule-left-cntr {
    max-height: calc(100vh - 230px);
  }
  .ant-tooltip-inner {
    padding-right: 9px;
    padding-left: 9px;
  }
  .schedule-btn-cntr {
    margin-left: -5px;
    flex-wrap: wrap;
    overflow: initial;
  }
  /* schedule modal ends */

  /* payment link mobile starts */
  .payment-details label {
    font-size: 12px;
    margin-bottom: 0;
  }
  .payment-details span {
    font-size: 16px;
  }
  .payment-details {
    margin-left: 5px;
  }
  /* payment link mobile ends */

  /* message suggestions starts */
  .suggestions-cntr span {
    font-size: 11px;
    padding-right: 10px;
    padding-left: 10px;
    margin: 2px 4px;
    max-width: 190px;
  }
  .suggestions-cntr {
    margin: 8px -4px 4px;
  }
  .suggestions-tick span:before {
    content: "";
    width: 15px;
    height: 15px;
  }
  .suggestions-tick span:after {
    content: "";
    width: 4px;
    height: 8px;
    right: 5.5px;
  }
  /* message suggestions ends */
  .uptabs .nav-tabs {
    margin: 9px 9px;
    padding: 0 2px;
    float: right;
  }
  ul.nameplate {
    margin: 15px 0;
  }
  .thirdbox .uptabs {
    overflow: hidden;
    max-height: 91px;
  }
  .thirdbox-shutter .uptabs {
    max-height: fit-content;
  }
  .prodtabl1 {
    max-height: 345px;
  }
  .nameplate .namelabel {
    padding: 6px 11px;
  }
  .nameplate li:first-child {
    margin-right: 6px;
  }
  .nameplate h3 {
    font-size: 13px;
    white-space: nowrap;
    max-width: 90px;
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
    margin-top: 5px;
  }
  .contactbox {
    float: right;
    width: 100%;
  }
  .updateprofilelinkbtn {
    margin: 10px 0;
    display: inline;
    float: right;
  }
  .pastchatmobbtn {
    display: inline;
    margin: 12px !important;
    float: left;
  }
  .productsbox .selectalllabel {
    margin-top: 12px;
  }
  .productsbox .prodboxx {
    margin-right: 5px;
  }
  .thirdbox .uptabs .tab-content {
    padding: 0 6px 15px;
  }
  .ladyimg {
    width: 90px;
    height: 125px;
    border: 1px solid #cac4c4;
  }
  .tabsbotbtn-box {
    text-align: inherit;
    margin: 10px 0;
  }
  .tabsbotbtn {
    min-width: 95px;
    width: 95px;
    margin-right: 5px;
    font-size: 11px;
    padding: 8px 8px;
  }
  .uptabs .nav-tabs .nav-link {
    padding: 8px 8px;
    font-size: 11px;
  }
}

@media all and (max-width: 425px) {
  .ant-pagination-item a {
    color: #4a4a4a !important;
  }
  .ant-pagination-item-active a {
    color: #4a4a4a !important;
  }
  .anticon svg {
    color: #4a4a4a;
  }
  .chat-face-cntr {
    width: 35px;
  }
  .chat-detail-cntr {
    margin-right: 20px;
  }
  .chatcontentDiv {
    /* height: calc(100vh - 299px); */
    /* height: calc(100vh - 389px); */
    height: calc(100vh - 285px);
  }
  .chat-detail-outer-cntr {
    min-height: 66px;
  }
  .online {
    width: 8px;
    height: 8px;
  }
  .chat-initial {
    height: 35px;
    line-height: 35px;
    font-size: 12px;
  }
  /* .chat-detail-outer-cntr .chat-detail-middle-cntr:first-child {
    padding-left: 2px;
  } */

  /* card modal starts */
  .mobile-card-img {
    flex: 0 0 70px;
    margin-right: 10px;
  }
  .chat-product-name {
    font-size: 8px;
  }
  .chat-product-code,
  .chat-product-prize,
  .chat-product-url {
    font-size: 7px;
  }
  .selectdot {
    width: 20px;
  }
  /* card modal ends */
}

@media all and (max-width: 387px) {
  .ant-pagination-item a {
    color: #4a4a4a !important;
  }
  .ant-pagination-item-active a {
    color: #4a4a4a !important;
  }
  .anticon svg {
    color: #4a4a4a;
  }
  /* schedule modal starts */
  .schedule-btn-cntr button,
  .select-slot-cntr,
  .schedule-right-cntr input {
    flex: 0 1 44%;
  }
  /* schedule modal ends */
}
/* Responsive ends */
